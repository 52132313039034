import styles from './Purpose.module.scss';
import classNames from 'classnames/bind';
import Purpose from '../../assets/Purpose.jpg';
import { useInView } from 'react-intersection-observer';

const cx = classNames.bind(styles);

function AboutUs() {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true,
    });
    return (
        <section className={cx('section')}>
            <div className={cx('container')} ref={ref}>
                <div className={cx('content')}>
                    <div className={cx(['image-container', { 'animation': inView }])}>
                        <img src={Purpose} alt="Eronka for the best"></img>
                    </div>
                    <div className={cx(['text-container', { 'animation': inView }])}>
                        <h2>Always seeking for <strong>the best.</strong></h2>
                        <p>
                            Eronka has been driving business success in global markets for over 13 years through the development of innovative product design solutions for companies striving to deliver world-leading medical devices, business equipment and consumer products.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
  
export default AboutUs;