import styles from './ContactBar.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

function ContactBar({ setHidePopUp }) {
    return (
        <div className={cx('bar')}>
            <ul className={cx('container')}>
                <li className={cx('desktop')} onClick={() => setHidePopUp(false)}>
                    <span>
                        <h4> Talk to us</h4> <h3>Start a project?</h3> 
                    </span>
                </li>
                <li className={cx('mobile')} onClick={() => setHidePopUp(false)}>
                    <span>
                        <h3>Start a project?</h3> <h4> Talk to us </h4> 
                    </span>
                </li>
            </ul>
        </div>
    );
}
  
export default ContactBar;