import { Route, Routes } from 'react-router-dom';

import './App.scss';
import Home from '../src/pages/Home';
import MeadiemPrivacyPolicy from '../src/pages/MeadiemPrivacyPolicy';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="container">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/meadiem-privacy-policy" element={<MeadiemPrivacyPolicy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
