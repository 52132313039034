import styles from './DesktopServicesClients.module.scss';
import classNames from 'classnames/bind';
import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import SoftwareDevelopment from '../../assets/service-software-development.jpg';
import HardwareDevelopment from '../../assets/service-hardware-development.jpg';
import DataScience from '../../assets/service-Data-Scientist-Analyst.jpg';
import Design from '../../assets/service-Design-Media-Architecture.jpg';
import Product from '../../assets/service-Product-Sourcing-Manufacturing.jpg';
import ScienceEngineerging from '../../assets/service-Engineering-Science.jpg';
import Sales from '../../assets/service-Sales-Marketing.jpg';
import Accounting from '../../assets/service-Business-functions-Accounting.jpg';
import Legal from '../../assets/service-Human-Resources-Legal.jpg';

import logos from '../../assets/19.svg';

const cx = classNames.bind(styles);

const services = [
    {
        id: 1,
        name: 'Software Development',
        photo: SoftwareDevelopment,
    },
    {
        id: 2,
        name: 'Hardware Development',
        photo: HardwareDevelopment,
    },
    {
        id: 3,
        name: 'Data Scientist Analyst',
        photo: DataScience,
    },
    {
        id: 4,
        name: 'Design, Media & Architecture',
        photo: Design,
    },
    {
        id: 5,
        name: 'Product Sourcing & Manufacturing',
        photo: Product,
    },
    {
        id: 6,
        name: 'Engineering & Science',
        photo: ScienceEngineerging,
    },
    {
        id: 7,
        name: 'Sales & Marketing',
        photo: Sales,
    },
    {
        id: 8,
        name: 'Business functions, Accounting',
        photo: Accounting,
    },
    {
        id: 9,
        name: 'Human Resources & Legal',
        photo: Legal,
    },
];

function AboutUs() {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true,
    });

    const [selectedService, setSelectedService] = useState(1)
    const renderedServices = services.map(({ id }) => (
        <li className={cx('item')} key={id}>
            <button className={(id === selectedService) && cx('active')} onClick={() => setSelectedService(id)} />
        </li>
    ));
    const renderedServicesContent = services.map(({ photo, name, id }) => {
        return (
            <div className={cx('item')}>
                <div className={cx(['service-photo', { 'activate': (id === selectedService) }])}>
                    <img src={photo} alt={name} />
                </div>
                <div className={cx(['service-name', { 'activate': (id === selectedService) }])}>
                    <h1>{name}</h1>
                </div>
            </div>
        );
    });
    useEffect(() => {
        const countTimer = setTimeout(() => {
            (selectedService < 9) 
                ? setSelectedService(selectedService => selectedService + 1)
                : setSelectedService(1);
        }, 4000);
        return () => {
            clearTimeout(countTimer);
        }
    }, 
        [selectedService]
    );

    return (
        <section 
            id="our_service"
            className={cx('section')}
        >
            <div className={cx('container')} ref={ref} >
                <div className={cx(['top-content', { 'animation': inView }])}>
                    <h2>Our <strong> services </strong> <br/>and <strong>clients</strong></h2>
                    <p>
                        Many believe they need a finished product or business model, customers, and early revenue before talking with us. The reality is that it’s a journey & we love to meet people at all stages of product research & development.
                    </p>
                </div>
                <div className={cx("contents")}>
                    <div className={cx(['service', { 'animation': inView }])}>
                        <div className={cx('service-content')}>
                            <ul className={cx('service-circle')}>
                                {renderedServices}
                            </ul>
                            <div className={cx('service-content__item')}>
                                {renderedServicesContent}
                            </div>
                        </div>
                    </div>
                    <div className={cx(['client', { 'animation': inView }])}>
                        <div className={cx('client-content')}>
                            <img src={logos} alt="logos" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
  
export default AboutUs;