import './Home.scss';
import { useState } from 'react';


import Top from '../components/Top/Top';
import MoveTop from '../components/MoveTop/MoveTop';
import ContactBar from '../components/ContactBar/ContactBar';
import AboutUs from '../components/AboutUs/AboutUs';
import Clients from '../components/Clients/Clients';
import OurService from '../components/OurService/OurService';
import DesktopServicesClients from '../components/DesktopServicesClients/DesktopServicesClients';
import ContactUs from '../components/ContactUs/ContactUs';
import Purpose from '../components/Purpose/Purpose'; 
import Reputation from '../components/Reputation/Reputation';
import Solutions from '../components/Solutions/Solutions';
import ContactPopUp from '../components/ContactPopUp/ContactPopUp';

function Home() {
  const [hidePopUp, setHidePopUp] = useState(true);
  const [recaptchaVerified, setRecaptchaVerified] = useState('check');
  return (
    <div className="container">
      {/* <Header /> */}
      <body>
        <Top />
        <MoveTop />
        <ContactBar setHidePopUp={setHidePopUp}/>
        <AboutUs />
        <Clients />
        <OurService />
        <DesktopServicesClients />
        <ContactUs setHidePopUp={setHidePopUp}/>
        <Purpose />
        <Reputation />
        <Solutions />
        <ContactPopUp 
          hidePopUp={hidePopUp} 
          setHidePopUp={setHidePopUp}
        />
      </body>
    </div>
  );
}

export default Home;
