import styles from './ContactUs.module.scss';
import classNames from 'classnames/bind';
import { useInView } from 'react-intersection-observer';

const cx = classNames.bind(styles);

function ContactUs({ setHidePopUp }) {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true,
    });
    return (
        <section 
            id="contact_us"
            className={cx('section')}
        >
            <div className={cx('bg-black')}>
                <div className={cx('container')} ref={ref}>
                    <div className={cx('content')}>
                        <div className={cx(['text-content', { 'animation': inView }])}>
                            <h1>We <strong>understand</strong> our clients</h1>
                            <p>
                                Over the years we’ve discovered that that embedding our team on your site is the best way to understand your business challenges. Working with various multinational organization we've consistently delivered commercial success for our clients by creating class-leading products and meaningful user experiences.
                            </p>
                        </div>
                        <button onClick={() => setHidePopUp(false)}>
                            Get in touch
                        </button>
                    </div>
                </div>

            </div>
        </section>
    );
}
  
export default ContactUs;