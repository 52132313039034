import styles from './ContactPopUp.module.scss';
import classNames from 'classnames/bind';
import PopupLogo from '../../assets/logo-popup.svg';
import backSVG from '../../assets/back.svg';
import loadingImage from '../../assets/loading.png';

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Recaptcha from 'react-recaptcha';

const cx = classNames.bind(styles);

export const QuickContact = ({ setHideQuickContact, setHideSelect, setHidePopUp }) => {
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState(false);

    const verifyCallbackRecaptcha = (e) => {
        !!e && setRecaptchaVerified(true);    
    }
    const handleBackButton = () => {
        setHideQuickContact(true);
        setHideSelect(false);
    }

    const { register: registerQuickContact, handleSubmit: handleSubmitQuickContact } = useForm();
    
    const sendSESRequest = async (data) => {
        setLoading(true);
        const res = await axios.post('https://p18pu6vr3b.execute-api.ap-southeast-2.amazonaws.com/default/Lambda_SES_HP', data);
        if( res.status === 200 ) {
            setResponseMessage('Email has sent successfully.')
            setTimeout(() => {
                setLoading(false);
                setHidePopUp(true);
                setHideQuickContact(true);
                setHideSelect(false);
            }, 3000);
        } else {
            setResponseMessage('Email sent failed please contact info@eronka.com through email.');
            setTimeout(() => {
                setLoading(false);
            }, 4000);
        }
    }
    const onSubmitQuickContact = async (data) => {
        const emailBody = `
            # This is notification from www.eronka.com hompage

            Type: Quick Contact

            Company: ${data.company}
            Name of contact: ${data.name}
            Phone number: ${data.phone}
            Purpose: ${data.purpose}
            Time: ${data.time}
            
            From Lambda SES
        `
        sendSESRequest(emailBody);
    }
    
    return (   
      <div className={cx(['content', 'project-quote'])}>
        <form onSubmit={handleSubmitQuickContact(onSubmitQuickContact)}>
            <div className={cx('header')}>
                <div className={cx('logo')}>
                    <img src={PopupLogo} alt="popup logo"/>
                </div>
                <div className={cx('back')} onClick={() => handleBackButton()} >
                    <img src={backSVG} alt="back"/>
                </div>
            </div>
            {loading 
                ?
                    (
                        responseMessage 
                            ? 
                                (
                                    <div className={cx('body')}>
                                        <div className={cx(['text-content', 'project-quote'])}>
                                            <h1 className={cx('project-quote')}>{responseMessage}</h1>
                                        </div>
                                    </div>
                                )
                            :
                                (
                                    <div className={cx('loading-image')}>
                                        <img src={loadingImage} alt="loading" />
                                    </div>
                                )
                    )
                :
                    (
                        <div className={cx('body')}>
                            <div className={cx(['text-content', 'project-quote'])}>
                                <h1 className={cx('project-quote')}>Got a question? Answer the phone</h1>
                            </div>
                            <div className={cx('input-content')}>
                                <label>
                                    Company
                                    <input
                                        type='text'
                                        name='company'
                                        ref={registerQuickContact}
                                        autoFocus
                                    />
                                </label>
                                <label>
                                    Full name
                                    <input
                                        type='text'
                                        name='name'
                                        ref={registerQuickContact}
                                        ></input>
                                </label>
                                <label>
                                    Telephone*
                                    <input
                                        type='text'
                                        name='phone'
                                        ref={registerQuickContact}
                                        ></input>
                                </label>
                                <label className={cx('select-title')}>
                                    Subject
                                    <br/>
                                    <div className={cx('purpose-select')}>
                                        <label>
                                            <span>New business</span>
                                            <input 
                                                name="purpose"
                                                ref={registerQuickContact} 
                                                type="radio" 
                                                value="New business" 
                                                />    
                                        </label>
                                        <label>
                                            <span>General Inquiry</span>
                                            <input 
                                                name="purpose" 
                                                ref={registerQuickContact}
                                                type="radio" 
                                                value="General Inquiry" 
                                                />
                                        </label>
                                    </div>
                                </label>
                                <label className={cx('select-title')}>
                                    Timetable
                                    <br/>
                                    <div className={cx('time-select')}>
                                        <label>
                                            <span>10:00AM - 11:00AM</span>
                                            <input name="time" ref={registerQuickContact} type="radio" value="10:00AM - 11:00AM" />
                                        </label>
                                        <label>
                                            <span>11:00AM - 12:00AM</span>
                                            <input name="time" ref={registerQuickContact} type="radio" value="11:00AM - 12:00AM" />
                                        </label>
                                        <label>
                                            <span>02:00PM - 03:00PM</span>
                                            <input name="time" ref={registerQuickContact} type="radio" value="02:00PM - 03:00PM" />
                                        </label>
                                        <label>
                                            <span>03:00PM - 04:00PM</span>
                                            <input name="time" ref={registerQuickContact} type="radio" value="03:00PM - 04:00PM" />
                                        </label>
                                    </div>
                                </label>
                            </div>
                        </div>
                    )
            }
            {!recaptchaVerified && (
                <div className={cx(['select-nav', 'project-quote'])}>
                    <Recaptcha
                        sitekey="6LcX0j8aAAAAAJsJSGwN2-cmKpc-ybF5pqPEfAX_"
                        render="explicit"
                        verifyCallback={(e) => verifyCallbackRecaptcha(e)}
                    />
                </div>
            )}
            {recaptchaVerified ? (
                !loading && (
                    <div className={cx(['select-nav', 'project-quote'])}>
                        <button className={cx(['project-quote'])} type="submit">
                            Send request
                        </button>
                    </div>
                )
            ) : (
                !loading && (
                    <div className={cx(['select-nav', 'project-quote'])}>
                        <button className={cx(['project-quote', 'disabled'])} disabled>
                            Send request
                        </button>
                    </div>
                )
            )}
        </form>
    </div>
  );
}
