import styles from './MoveTop.module.scss';
import classNames from 'classnames/bind';
import UpperArrow from '../../assets/UpperArrow.svg';

const cx = classNames.bind(styles);

function ContactPopUp() {
    return (
        <div className={cx('move-top')}>
            <div className={cx('container')}>
                <a href="#eronka">
                    <img src={UpperArrow} alt="Move to top" />
                </a>
            </div>
        </div>
    );
}
  
export default ContactPopUp;