import styles from './Clients.module.scss';
import classNames from 'classnames/bind';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useInView } from 'react-intersection-observer';

import leftArrow from '../../assets/left-arrow.svg';
import rightArrow from '../../assets/right-arrow.svg';
import LGLogo from '../../assets/LG-logo.svg';
import NespressoLogo from '../../assets/Nespresso-logo.svg';
import SharpLogo from '../../assets/Sharp-logo.svg';
import PurinaLogo from '../../assets/Purina-logo.svg';
import WestpacLogo from '../../assets/Westpac-logo.svg';
import NestleLogo from '../../assets/Nestle-logo.svg';


const cx = classNames.bind(styles);
const clientsLogo = {
  LG: {
    logo: LGLogo
  }, 
  Nespresso: {
    logo: NespressoLogo
  }, 
  Sharp: {
    logo: SharpLogo
  }, 
  Purina: {
    logo: PurinaLogo
  }, 
  Westpac: {
    logo: WestpacLogo
  }, 
  Nestle: {
    logo: NestleLogo
  }
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={cx(['left-arrow'])} >
      <img
        className = {cx([className])}
        style={{ ...style}}
        onClick={onClick}
        src={leftArrow}
        alt={className}
      />
    </div>
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={cx(['right-arrow'])} >
      <img
        className = {cx([className])}
        style={{ ...style }}
        onClick={onClick}
        src={rightArrow}
        alt={className}
      />
    </div>
  );
}

function Clients() {
    const { ref, inView } = useInView({
      /* Optional options */
      threshold: 0,
      triggerOnce: true,
    });
    
    const renderClientsLogo = Object.keys(clientsLogo).map((el) => (
      <div className={cx('clients-logo')} key={el}>
        <img src={clientsLogo[el].logo} alt="logo"/>
      </div>
    ));

    const settings = {
        infinite: true,
        speed: 800,
        autoplaySpeed: 4000,
        slidesToShow: 2,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        className: "center",
        centerMode: false,
        responsive: [
            
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ],
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };
    return (
        <section 
          className={cx('section')}
          ref={ref}
        >
            <div className={cx('container')} ref={ref}>
                <div className={cx(['content', { 'animation': inView }])}>
                  <h2>Our clients</h2>
                  <p>
                      Many believe they need a finished product or business model, customers, and early revenue before talking with us. The reality is that it’s a journey & we love to meet people at all stages of product research & development.
                  </p>
                </div>
                <div className={cx('carousel')}> 
                    <Slider {...settings}>
                        {renderClientsLogo}
                    </Slider>
                </div>
            </div>
        </section>
    );
}
  
export default Clients;
