import styles from './Reputation.module.scss';
import classNames from 'classnames/bind';
import clientImage from '../../assets/m_lambert.png';
import { useInView } from 'react-intersection-observer';

const cx = classNames.bind(styles);

function ContactUs() {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true,
    });
    return (
        <section className={cx('section')}>
            <div className={cx('bg-black')}>
                <div className={cx('container')} ref={ref}>
                    <div className={cx('content')}>
                        <div className={cx('text-content')}>
                            <h1 className={cx([{ 'animation': inView }])}>
                                What’s do clients<br/>
                                <strong>say about</strong> us?
                            </h1>
                            <div className={cx(['markus', { 'animation': inView }])}>
                                <p>
                                    Don’t be surprised if you find yourself gradually giving them more work. This is a testiment to the quality of team and service they provide.
                                </p>
                                <div className={cx('client-content')}>
                                    <img src={clientImage} alt="Markus Lambert" />
                                    <p>
                                        Markus Lambert, LG
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}
  
export default ContactUs;