import './MeadiemPrivacyPolicy.scss';

function MeadiemPrivacyPolicy() {
  return (
    <div className="contents">
        <main className="contents-container">
            <div className="contents-title">
                <h1>Meadiem privacy policy</h1>
            </div>
            <div className="contents-detail">
                <h2>Biometric Data Privacy Policy</h2>
                <h3>Effective Date: 12/05/2022</h3>
                <h4>Meadiem values your privacy rights and is committed toward transparency in all of our products and services. We want you to be fully informed of how your personal information will be used.</h4>
            </div>

            <div className="contents-detail">
                <h2>Scope</h2>
                <h4>This Biometric Data Privacy Policy applies to the collection, use, and storing of your image that is collected by Meadiem, its agents, representatives, assigns, subsidiaries, affiliated companies, successors in interest, licensees, and authorized vendors ("Meadiem"), through your use of the Meadiem Skin Report’s facial analyzer tool and the camera used to collect the image, and where such representation of your image may be considered "biometric data", "biometric identifiers", or "biometric information" pursuant to certain laws in the United States (collectively, the “Biometric Data”). The Meadiem Skin Report is not intended to be used by residents of Baltimore, Maryland, or Portland, Oregon. Do not use the Meadiem Skin Report if you reside in these cities.</h4>
            </div>

            <div className="contents-detail">
                <h2>Purpose</h2>
                <h4>Meadiem processes the Biometric Data obtained through collection of your image only at your direction and with your consent, and only for the purposes set forth in this policy. You may choose not to use the Meadiem Skin Report and you may choose not to have your image collected by Meadiem. If you choose to use the Meadiem Skin Report, you authorize and voluntarily consent to have Meadiem collect, use, and store Biometric Data for the purposes of facilitating your use of the Meadiem Skin Report, including skin analysis and assessment, providing you with customized product recommendations, the ability to demonstrate changes to your skin over time, research and development (including for service improvements and machine learning), or any other purpose not inconsistent with state or federal laws or regulations (the "Purpose"). Meadiem may use authorized third-party vendors and licensors to supply it with the technology needed to offer the Meadiem Skin Report.</h4>
            </div>

            <div className="contents-detail">
                <h2>Retention and Deletion</h2>
                <h4>Biometric Data is stored securely in Meadiem' internally managed database and/or with secure cloud service providers. Your Biometric Data will be deleted the earlier of (1) when the initial purpose for collecting or obtaining such Biometric Data has been satisfied, (2) within 2 years of your last interaction with the Meadiem Skin Report, or (3) upon your request for us to delete your data from our database.</h4>
            </div>

            <div className="contents-detail">
                <h2>Sharing of Biometric Data</h2>
                <h4>Meadiem does not sell, lease, trade, or use the Biometric Data for commercial purposes other than for the purpose set forth in this policy. Meadiem does not authorize any third parties who are not our authorized data processors to use the Biometric Data in a manner inconsistent with this policy or the Meadiem Privacy Policy. Meadiem will not share Biometric Data with any other third party, except with its corporate affiliates, unless (1) you provide written consent to share, or (2) disclosure is permitted or required by applicable law, or is in response to subpoenas, court orders, or other legal processes.</h4>
            </div>

            <div className="contents-detail">
                <h2>Data Storage & Security</h2>
                <h4>Meadiem uses a reasonable standard of care to store, transmit, and protect from disclosure any Biometric Data collected. Such storage, transmission, and protection from disclosure shall be performed in a manner that is the same or more protective than the manner in which Meadiem stores, transmits, and protects from disclosure other confidential and sensitive information, including personal information that can be used to uniquely identify an individual.</h4>
            </div>
        </main>
    </div>
  );
}

export default MeadiemPrivacyPolicy;
