import styles from './Top.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

function Header() {
    return (
        <section className={cx('section')}>
            <div className={cx('container')}>
                <div className={cx('introduction')}>
                    <h1>
                        We’re a 
                        <strong> technology </strong>
                        research & development company bringing ideas to life for our 
                        <strong> clients </strong>
                    </h1>
                </div>
            </div>
        </section>
    );
}
  
export default Header;