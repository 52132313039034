import styles from './OurService.module.scss';
import classNames from 'classnames/bind';
import { DiCodeBadge } from 'react-icons/di';
import { IoHardwareChipOutline } from 'react-icons/io5';
import { BsClipboardData } from 'react-icons/bs';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { GiMaterialsScience } from 'react-icons/gi';
import { VscArchive } from 'react-icons/vsc';
import { IoIosRadio } from 'react-icons/io';
import { IoBusinessOutline } from 'react-icons/io5';
import { VscLaw } from 'react-icons/vsc';
import { useInView } from 'react-intersection-observer';

const cx = classNames.bind(styles);

const services = [
    {
        name: 'Software Development',
        content: 'Social media development, mobile application development (Social, Commercial, Retail & Gaming), Web application development.',
        photo: <DiCodeBadge size="2.5rem"/>,
    },
    {
        name: 'Hardware Development',
        content: 'Computer hardware, firmware, design verification.',
        photo: <IoHardwareChipOutline size="2.5rem"/>,
    },
    {
        name: 'Data Scientist Analyst',
        content: 'Data mining, data analysis, with using a variety of data tools, building and implementing models.',
        photo: <BsClipboardData size="2.5rem"/>,
    },
    {
        name: 'Design, Media & Architecture',
        content: 'User interface design (UX/UI), graphic design & illustrations, photography & video production.',
        photo: <AiOutlineFundProjectionScreen size="2.5rem"/>,
    },
    {
        name: 'Product Sourcing & Manufacturing',
        content: 'Consulting with manufacturing processes.',
        photo: <VscArchive size="2.5rem"/>,
    },
    {
        name: 'Engineering & Science',
        content: 'Research, develop, and design new materials, devices, sensors, and processes for a diverse range of applications.',
        photo: <GiMaterialsScience size="2.5rem"/>,
    },
    {
        name: 'Sales & Marketing',
        content: 'Branding & identity, online/offline strategy.',
        photo: <IoIosRadio size="2.5rem"/>,
    },
    {
        name: 'Business functions, Accounting',
        content: 'Imagine & engagement.',
        photo: <IoBusinessOutline size="2.5rem"/>,
    },
    {
        name: 'Human Resources & Legal',
        content: 'The Human Resources & General Affairs team provides expertise in recruitment solutions to a broad spectrum. Our clients include Consumer, Industrial, Healthcare, Technology, and Financial and Professional Services companies.',
        photo: <VscLaw size="2.5rem"/>,
    },
];

function AboutUs() {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true,
    });
    const renderedServices = services.map(({ photo, name, content }) => (
        <div className={cx('item')}>
            <div className={cx('icon')}>
                {photo}
            </div>
            <h5>{name}</h5>
            <p>{content}</p>
        </div>
    ));
    return (
        <section 
            id="our_service"
            className={cx('section')}
        >
            <div className={cx('container')} ref={ref}>
                <div className={cx(['top-content', { 'animation': inView }])}>
                    <h2>What we do</h2>
                    <p>
                        Many believe they need a finished product or business model, customers, and early revenue before talking with us. The reality is that it’s a journey & we love to meet people at all stages of product research & development.
                    </p>
                </div>
                <div className={cx(['sub-content', { 'animation': inView }])}>
                    {renderedServices}
                </div>
            </div>
        </section>
    );
}
  
export default AboutUs;