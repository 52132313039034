import styles from './ContactPopUp.module.scss';
import classNames from 'classnames/bind';
import PopupLogo from '../../assets/logo-popup.svg';
import backSVG from '../../assets/back.svg';
import loadingImage from '../../assets/loading.png';

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Recaptcha from 'react-recaptcha';

const cx = classNames.bind(styles);

export const ProjectQuote = ({ setHideProjectQuote, setHideSelect, setHidePopUp }) => {
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(false);

  const verifyCallbackRecaptcha = (e) => {
      !!e && setRecaptchaVerified(true);    
  }
  const handleBackButton = () => {
    setHideProjectQuote(true);
    setHideSelect(false);
  }
    
    const { register: registerProjectQuote, handleSubmit: handleSubmitProjectQuote } = useForm();
    
    const sendSESRequest = async (data) => {
        setLoading(true);
        const res = await axios.post('https://p18pu6vr3b.execute-api.ap-southeast-2.amazonaws.com/default/Lambda_SES_HP', data);
        if( res.status === 200 ) {
            setResponseMessage('Email has sent successfully.')
            setTimeout(() => {
                setLoading(false);
                setHidePopUp(true);
                setHideProjectQuote(true);
                setHideSelect(false);
            }, 3000);
        } else {
            setResponseMessage('Email sent failed please contact info@eronka.com through email.');
            setTimeout(() => {
                setLoading(false);
            }, 4000);
        }
    }

    const onSubmitProjectQuote = async (data) => {
        const emailBody = `
            # This is notification from www.eronka.com hompage

            Type: Project Quote

            Company: ${data.company}
            Name of contact: ${data.name}
            Email address: ${data.email}
            Phone number: ${data.phone}
            Subject: ${data.subject}
            Message: ${data.message}
            
            From Lambda SES
        `
        console.log('onSubmitProjectQuote');
        sendSESRequest(emailBody);
    }
    
    return (
      <div className={cx(['content', 'project-quote'])}>
          <form onSubmit={handleSubmitProjectQuote(onSubmitProjectQuote)}>
              <div className={cx('header')}>
                  <div className={cx('logo')}>
                      <img src={PopupLogo} alt="popup logo"/>
                  </div>
                  <div className={cx('back')} onClick={() => handleBackButton()} >
                      <img src={backSVG} alt="back"/>
                  </div>
              </div>
              {loading 
                ?
                    (
                        responseMessage 
                            ? 
                                (
                                    <div className={cx('body')}>
                                        <div className={cx(['text-content', 'project-quote'])}>
                                            <h1 className={cx('project-quote')}>{responseMessage}</h1>
                                        </div>
                                    </div>
                                )
                            :
                                (
                                    <div className={cx('loading-image')}>
                                        <img src={loadingImage} alt="loading" />
                                    </div>
                                )
                    )
                :
                    (
                        <div className={cx('body')}>    
                            <div className={cx(['text-content', 'project-quote'])}>
                                <h1 className={cx('project-quote')}>Write us. The best is yet to come</h1>
                            </div>
                            <div className={cx('input-content')}>
                                <label>
                                    Company
                                    <input
                                        type='text'
                                        name='company'
                                        ref={registerProjectQuote}
                                        autoFocus
                                    />
                                </label>
                                <label>
                                    Full name
                                    <input
                                        type='text'
                                        name='name'
                                        ref={registerProjectQuote}
                                        ></input>
                                </label>
                                <label>
                                    Email*
                                    <input
                                        type='text'
                                        name='email'
                                        ref={registerProjectQuote}
                                        ></input>
                                </label>
                                <label>
                                    Telephone*
                                    <input
                                        type='text'
                                        name='phone'
                                        ref={registerProjectQuote}
                                        ></input>
                                </label>
                                <label>
                                    Subject
                                    <input
                                        type='text'
                                        name='subject'
                                        ref={registerProjectQuote}
                                        ></input>
                                </label>
                                <label>
                                    Message
                                    <textarea
                                        type='text'
                                        name='message'
                                        ref={registerProjectQuote}
                                        ></textarea>
                                </label>
                            </div>
                        </div>
                    )
                }
              {!recaptchaVerified && (
                <div className={cx(['select-nav', 'project-quote'])}>
                    <Recaptcha
                        sitekey="6LcX0j8aAAAAAJsJSGwN2-cmKpc-ybF5pqPEfAX_"
                        render="explicit"
                        verifyCallback={(e) => verifyCallbackRecaptcha(e)}
                    />
                </div>
              )}
                {recaptchaVerified ? (
                    !loading && (
                        <div className={cx(['select-nav', 'project-quote'])}>
                            <button className={cx(['project-quote'])} type="submit">
                                Send request
                            </button>
                        </div>
                    )
                ) : (
                    !loading && (
                        <div className={cx(['select-nav', 'project-quote'])}>
                            <button className={cx(['project-quote', 'disabled'])} disabled>
                                Send request
                            </button>
                        </div>
                    )
                )}
          </form>
      </div>          
    );
}