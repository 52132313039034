import styles from './AboutUs.module.scss';
import classNames from 'classnames/bind';
import AboutUsPhoto from '../../assets/AboutUs.jpg';
import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const cx = classNames.bind(styles);

function AboutUs() {
    const [years, setYears] = useState(0);
    const [clients, setClients] = useState(0);
    const [projects, setProjects] = useState(0);
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true,
    });

    useEffect(() => {
            const serviceYear = new Date().getFullYear() - 2006 + 1;
            if(inView) {
                const countTimer = setTimeout(() => {
                    years <= serviceYear && setYears(years => years + 1);
                    clients < 50 && setClients(clients => clients + 1);
                    projects < 85 && setProjects(projects => projects + 1);
                }, 20);
                return () => {
                    clearTimeout(countTimer);
                }
            }
        }, 
        [years, clients, projects, inView]
    );

    return (
        <section 
            id="about_us"
            className={cx('section')}
        >
            <div className={cx('container')}>
                <div className={cx('top-content')} ref={ref}>
                    <div className={cx(['text-container', { 'animation': inView }])}>
                        <div className={cx('text')}>
                            <h2>Passion, <strong>obsession & iteration.</strong></h2>
                            <p>
                                These are values we bring to all projects we initate.
                            </p>
                            <p>
                                Good things take time and long-term investment. This is why we're happy to co-invest as a way of showing we have "Skin in the game".
                            </p>
                        </div>
                        <div className={cx(['counter-container', { 'animation': inView } ])}>
                            <div className={cx('counter-item')}>
                                <h1>{inView && years}</h1>
                                <p>YEARS OLD</p>
                            </div>
                            <div className={cx('counter-item')}>
                                <h1>{inView && clients}+</h1>
                                <p>CLIENTS</p>
                            </div>
                            <div className={cx('counter-item')}>
                                <h1>{inView && projects}+</h1>
                                <p>COMMERCIALIZED<br/> PROJECTS</p>
                            </div>
                        </div>
                    </div>
                    <div className={cx(['image-container', { 'animation': inView }])}>
                        <img src={AboutUsPhoto} alt="team office"></img>
                    </div>
                </div>
            </div>
        </section>
    );
}
  
export default AboutUs;