import styles from './Header.module.scss';
import classNames from 'classnames/bind';
import logo from '../../assets/logo.svg';
import { useState, useEffect } from 'react';

const cx = classNames.bind(styles);

function Header() {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [hideHead, setHideHead] = useState(false);

    const handleScroll = (e) => {
        console.log(e, 1);
        (e.currentTarget.scrollY > 200) ? setHideHead(true) : setHideHead(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', (e) => handleScroll(e), { passive: true });
        console.log(hideHead);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={cx({ 'hide': hideHead })} id="eronka">
            <div className={cx('container')}>
                <div className={cx('header-logo')}>
                    <img src={logo} alt="header-logo"></img>
                </div>
                <div className={cx(["header-menu", { "show": toggleMenu }])}>
                    <ul>
                        <li><a href="/#about_us" onClick={() => setToggleMenu(false)}>Who we are</a></li>
                        <li><a href="/#contact_us" onClick={() => setToggleMenu(false)}>Contact us</a></li>
                    </ul>
                </div>
                <button 
                    className={cx(["header-toggle", { "toggle-close": toggleMenu }])} 
                    onClick={() => setToggleMenu(!toggleMenu)}
                >
					<span></span>
				</button>
            </div>
        </header>
    );
}
  
export default Header;