import styles from './Footer.scss';
import classNames from 'classnames/bind';
import logo from '../../assets/logo.svg';
import facebook from '../../assets/facebook.svg';
import linkedin from '../../assets/linkedin.svg';
const cx = classNames.bind(styles);

function Header() {
  return (
    <footer>
      <div className={cx('container')}>
        <div className={cx('items')}>
          <img src={logo} alt="logo"></img>
          <p className={cx('copyright')}>© 2022 Eronka, All Rights Reserved.</p>
          <p className={cx('address')}>101/49 Queens Rd, Five Dock NSW 2046, Australia</p>
          <p className={cx('contact')}>hello@eronka.com</p>
          <ul className={cx('social-media')}>
            <li>
              <a rel="noreferrer" href="https://www.facebook.com/eronkaco" target="_blank">
                <img src={facebook} alt="facebook" />
              </a>
            </li>
            <li>
              <a rel="noreferrer" href="https://www.linkedin.com/company/eronka/about" target="_blank">
                <img src={linkedin} alt="facebook" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Header;
