import styles from './ContactPopUp.module.scss';
import classNames from 'classnames/bind';
import PopupLogo from '../../assets/logo-popup.svg';
import closeSVG from '../../assets/x.svg';

import { useState } from 'react';
import { ProjectQuote } from './ProjectQuote';
import { QuickContact } from './QuickContact';

const cx = classNames.bind(styles);

function ContactPopUp({ hidePopUp, setHidePopUp }) {
    const [hideSelect, setHideSelect] = useState(false);
    const [hideProjectQuote, setHideProjectQuote] = useState(true);
    const [hideQuickContact, setHideQuickContact] = useState(true);
    const handleStartProject = () => {
        setHideProjectQuote(false);
        setHideSelect(true);
    }
    const handleQuickContact = () => {
        setHideQuickContact(false);
        setHideSelect(true);
    }
    return (
        <section className={cx(['section', { 'activate': hidePopUp }])}>
            <div className={cx('container')}>
                {!hideSelect && (<div className={cx('content')}>
                    <div className={cx('header')}>
                        <div className={cx('logo')}>
                            <img src={PopupLogo} alt="popup logo"/>
                        </div>
                        <div className={cx('close')} onClick={() => setHidePopUp(true)}>
                            <img src={closeSVG} alt="close"/>
                        </div>
                    </div>
                    <div className={cx('body')}>
                        <div className={cx('text-content')}>
                            <h1>How can we help you today?</h1>
                        </div>
                    </div>
                    <div className={cx('select-nav')}>
                        <button onClick={() => handleStartProject()}>
                            Start a project
                        </button>
                        <button onClick={() => handleQuickContact()}>
                            Quick contact
                        </button>
                    </div>
                </div>)
}
                {!hideProjectQuote && (<ProjectQuote 
                    setHideSelect={setHideSelect}
                    setHideProjectQuote={setHideProjectQuote}
                    setHidePopUp={setHidePopUp}
                />)}
                {!hideQuickContact && (<QuickContact
                    setHideSelect={setHideSelect}
                    setHideQuickContact={setHideQuickContact}
                    setHidePopUp={setHidePopUp}
                />)}
            </div>
        </section>
    );
}
  
export default ContactPopUp;